.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    -webkit-transition: all .25s ease-in;
    -moz-transition: all .25s ease-in;
    -ms-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transition: all .25s ease-in;
    opacity: .7;
    position: absolute;
    z-index: 2;
    top: 20px;
    background: 0 0;
    border: 0;
    font-size: 32px;
    cursor: pointer
}

.carousel .control-arrow:hover {
    opacity: 1
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: ''
}

.carousel .control-disabled.control-arrow {
    opacity: .7;
    cursor: inherit;
    display: none
}

.carousel .control-prev.control-arrow {
    left: 0
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #33691e
}

.carousel .control-next.control-arrow {
    right: 0
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #33691e
}

.carousel {
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none
}

.carousel .carousel {
    position: relative
}

.carousel .control-arrow {
    outline: 0;
    border: 0;
    background: 0 0;
    top: 50%;
    margin-top: -13px;
    font-size: 18px
}

.carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden
}

.carousel .thumbs {
    -webkit-transition: all .15s ease-in;
    -moz-transition: all .15s ease-in;
    -ms-transition: all .15s ease-in;
    -o-transition: all .15s ease-in;
    transition: all .15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap
}

.carousel .thumb {
    -webkit-transition: border .15s ease-in;
    -moz-transition: border .15s ease-in;
    -ms-transition: border .15s ease-in;
    -o-transition: border .15s ease-in;
    transition: border .15s ease-in;
    display: inline-block;
    width: 80px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px
}

.carousel .thumb:focus {
    border: 3px solid #ccc;
    outline: 0
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 3px solid #333
}

.carousel .thumb img {
    vertical-align: top
}

.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden
}

.carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(252, 250, 250, 0.2)
}

.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    -webkit-transition: height .15s ease-in;
    -moz-transition: height .15s ease-in;
    -ms-transition: height .15s ease-in;
    -o-transition: height .15s ease-in;
    transition: height .15s ease-in
}

.carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column
}

.carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex
}

.carousel .slider-wrapper.axis-vertical .slider {
    -webkit-flex-direction: column;
    flex-direction: column
}

.carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%
}

.carousel .slider.animated {
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out
}

.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: rgb(253, 251, 251)
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0
}

.carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0
}

.carousel .slide .legend {
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: .25;
    -webkit-transition: opacity .35s ease-in-out;
    -moz-transition: opacity .35s ease-in-out;
    -ms-transition: opacity .35s ease-in-out;
    -o-transition: opacity .35s ease-in-out;
    transition: opacity .35s ease-in-out
}

.carousel .control-dots {
    position: absolute;
    z-index: 999;
    bottom: 0;
    margin: 10px 0;
    text-align: center;
    width: 100%
}

@media (min-width:960px) {
    .carousel .control-dots {
        bottom: 0
    }
}

.carousel .control-dots .dot {
    -webkit-transition: opacity .25s ease-in;
    -moz-transition: opacity .25s ease-in;
    -ms-transition: opacity .25s ease-in;
    -o-transition: opacity .25s ease-in;
    transition: opacity .25s ease-in;
    opacity: .9;
    box-shadow: none;
    background: #598ea8;
    border-radius: 0%;
    width: 13px;
    height: 3px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    opacity: 1;
    background-color: #0744a0
}

.carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .9);
    color: #598ea8
}

.carousel:hover .slide .legend {
    opacity: 1
}